export const stub = [
  {
    id: 140,
    name: 'Гайнанов Р. Ш.',
    position: 'Генеральный директор ООО «ТИМ ФОРС»',
    description: `ООО «ТИМ ФОРС» выражает глубокую заинтересованность в результатах научно-исследовательских работ, проведенных Морозовым Максимом Александровичем и его командой в области разработки решений с применением технологий Federated Learning, и готовность к реализации совместных проектов в этом направлении.<br/><br/>

Команда Максима Александровича готова предложить рынку сервис, аналогов которому еще нет в Российской Федерации, но польза от которого подтверждена нашим многолетним опытом работы с компаниями, работающих в финансовом и промышленном секторах экономики. Мы заметили, что наши заказчики постоянно сталкиваются с ситуациями, когда для масштабной разработки МL-моделей им не хватает бюджетов, данных, открытыми остаются вопросы информационной безопасности. Решение, предоставляющее глобальные МL-модели по подписке, использующее для их обучения технологии Federated Learning, способно закрыть потребности заказчиков и обеспечить непрерывное улучшение бизнеса.<br/><br/>

Сервис востребован как на российском, так и на международном рынке, а профессионализм и компетенции команды Максима Александровича позволят добиться реализации сервиса в ближайшие сроки.`,
    short_description:
      'ООО «ТИМ ФОРС» выражает глубокую заинтересованность в результатах научно-исследовательских работ, проведенных Морозовым Максимом Александровичем и его командой в области разработки решений...',
    photo:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/landings/astonlab/reviews/ruslan.jpg',
  },
  {
    id: 141,
    name: 'Сапожников К. М.',
    position: 'Руководитель направления по работе с поставщиками ИТ-ресурсов АО «Альфа-Банк»',
    description: `АО  «Альфа-Банк» выражает Вам своё уважение и информирует об успешном  сотрудничестве с компанией Aston в области реализации IT-проектов.<br/><br/>
Сотрудники компании Aston участвуют в разработке банковских информационных систем в составе проектных команд АО «Альфа-Банк». Совместная работа построена так, что новые члены команды со стороны Aston быстро интегрируются в команду в сопровождении менторов со стороны АО «Альфа-Банк» и решают поставленные задачи в срок.<br/><br/>
Благодарим компанию Aston за неизменно высокое качество предоставляемых услуг, клиентоориентированность и гибкий подход к решению поставленных задач. Скорость и качество формирования проектных команд соответствуют всем предъявляемым со стороны заказчика требованиям.<br/><br/>
С уверенностью рекомендуем компанию Aston как надежного партнёра при реализации IT-проектов.`,
    short_description:
      'АО  «Альфа-Банк» выражает Вам своё уважение и информирует об успешном  сотрудничестве с компанией Aston в области реализации IT-проектов. Сотрудники компании Aston участвуют в разработке банковских информационных систем в составе проектных команд АО...',
    photo:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/landings/astonlab/reviews/dummy.jpg',
  },
  {
    id: 142,
    name: 'Аршиниченко Е. И.',
    position: 'Технический директор «Тинькофф»',
    description: `Тинькофф уже более 3 лет активно сотрудничает с компанией Aston в области реализации IT-проектов. С помощью специалистов Aston в области разных технологий (Java, .Net, Angular, QA, DevOps и ВА) мы смогли расширить и укрепить наши команды, что с учетом нашего активного роста считаем значимым положительным эффектом.<br/><br/>

Мы должны отметить, что качество предоставляемых Aston услуг неизменно находится на высоком уровне - подтверждаем высокий профессионализм сотрудников, задействованных на проектах, их клиентоориентированность и способность решать трудные задачи в сжатые сроки.<br/><br/>

С уверенностью рекомендуем Aston как надежного партнера при реализации IT-проектов.`,
    short_description:
      'Тинькофф уже более 3 лет активно сотрудничает с компанией Aston в области реализации IT-проектов. С помощью специалистов Aston в области разных технологий (Java, .Net, Angular, QA, DevOps и ВА) мы смогли расширить и укрепить наши команды, что с учетом нашего активного...',
    photo:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/landings/astonlab/reviews/dummy.jpg',
  },
  {
    id: 143,
    name: 'Шапка А. В.',
    position: 'Генеральный директор ООО «СмартСтаффинг»',
    description: `
    Наша компания уже много лет сотрудничает с вами в области реализации ИТ-проектов‚ и мы хотим отметить неизменное отличное качество выполняемых работ и услуг. Также отметим высокий профессионализм ваших сотрудников и глубокие экспертные знания в области финансовых технологий, способность и готовность решать любые задачи в заданные сроки.<br/><br/>

Со своей стороны сообщаем, что ознакомились с результатами ваших научно-исследовательских работ в области применения технологий Federated Learning. Выражаем согласие с тем, что данная технология имеет мощный потенциал развития на  российском рынке, а также готовность к реализации совместных исследований и проектов в этом направлении.
`,
    short_description:
      'Наша компания уже много лет сотрудничает с вами в области реализации ИТ-проектов‚ и мы хотим отметить неизменное отличное качество выполняемых работ и услуг. Также отметим высокий профессионализм ваших сотрудников и глубокие экспертные знания в области финансовых...',
    photo:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/landings/astonlab/reviews/dummy.jpg',
  },
];
