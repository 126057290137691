import React from 'react';

import { Head, Footer, Header } from 'landing/components';
import Intro from 'components/Intro';
import OurTeam from 'components/OurTeam';
import WhatWeDo from 'components/WhatWeDo';
import Reviews from 'components/Reviews';
import GetConsultation from 'components/GetConsultation';
import AboutProject from 'components/AboutProject';
import CookiesPolicy from 'components/CookiesPolicy';

import { anchorLinks } from 'define/anchorLinks';

import data from './data.json';

function Index() {
  return (
    <>
      <Head {...data.head} />
      <Header anchorLinks={anchorLinks} />
      <Intro {...data.intro} />
      <AboutProject {...data.aboutProject} />
      <WhatWeDo {...data.whatWeDo} />
      <OurTeam {...data.ourTeam} />
      <Reviews {...data.reviews} />
      <GetConsultation {...data.getConsultation} />
      <Footer {...data.footer} />
      <CookiesPolicy />
    </>
  );
}
export default Index;
