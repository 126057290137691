import React from 'react';
import cn from 'classnames';

import PersonalData from './PersonalData';

import * as styles from './ReviewInfo.module.scss';
import { Icon, Markup } from 'landing/ui';

export default function ReviewInfo({
  allowHint,
  budget,
  case_url,
  clutch_link,
  company,
  company_link,
  company_logo,
  country,
  country_icon,
  description,
  domain,
  duration,
  good_firms_link,
  hideLink,
  linkedin,
  name,
  ninety_nine_firms_link,
  photo,
  position,
  short_description,
  showMoreActive,
  showShowMore,
  summary,
  team_size,
  technology,
  theme,
  toggleShowMore,
  video,
}) {
  const showMoreVisible = showShowMore ?? document.body.clientWidth < 885;

  return (
    <article className={cn(styles.card, theme && styles[theme], styles.slide)}>
      <div className={styles.wrapper}>
        <section className={styles.section}>
          <div className={styles.description}>
            <div className={styles.icon}>
              <Icon name={'quote'} />
            </div>
            <div className={styles.textWrapper}>
              <div>
                <Markup tag="p" className={cn(styles.review, !showMoreActive && styles.showFull)}>
                  {description}
                </Markup>
                <div className={styles.showMoreButtonRow}>
                  <button
                    onClick={toggleShowMore}
                    className={cn(styles.moreLessButton, showMoreActive && styles.less)}
                  >
                    {showMoreActive ? 'Скрыть' : 'Читать полностью'}
                  </button>
                </div>
              </div>
              <PersonalData
                photo={photo}
                name={name}
                linkedin={linkedin}
                position={position}
                company={company}
                companyLink={company_link}
              />
            </div>
          </div>
        </section>
      </div>
    </article>
  );
}
