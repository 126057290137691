// extracted by mini-css-extract-plugin
export var arrowRight = "AboutProject-module--arrowRight--86316";
export var box = "AboutProject-module--box--62f11";
export var btn = "AboutProject-module--btn--e6a53";
export var button = "AboutProject-module--button--0d58e";
export var count = "AboutProject-module--count--db58a";
export var description = "AboutProject-module--description--a76e8";
export var gridItem = "AboutProject-module--gridItem--5784e";
export var icon = "AboutProject-module--icon--8c899";
export var inner = "AboutProject-module--inner--b243a";
export var item = "AboutProject-module--item--2d089";
export var itemImage = "AboutProject-module--itemImage--6ba9c";
export var itemTitle = "AboutProject-module--itemTitle--60258";
export var link = "AboutProject-module--link--77ab8";
export var linkWrapper = "AboutProject-module--linkWrapper--ffff3";
export var list = "AboutProject-module--list--7ddbe";
export var outer = "AboutProject-module--outer--53d1b";
export var statistics = "AboutProject-module--statistics--1a526";
export var subtitleItem = "AboutProject-module--subtitleItem--ff283";
export var text = "AboutProject-module--text--776bf";
export var titleSecond = "AboutProject-module--titleSecond--9ed6b";
export var wrapper = "AboutProject-module--wrapper--db5c8";