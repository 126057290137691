import React, { useState, useCallback, useMemo } from 'react';
import cn from 'classnames';
import ReviewInfo from 'components/ReviewInfo';
import {
  Container,
  Title,
  Subtitle,
  Loader,
  Slider,
  CustomDot,
  CustomPagination,
  Image,
} from 'landing/ui';

import * as styles from './Reviews.module.scss';
import 'landing/ui/lib/Slider/globalReviews.scss';
import { stub } from './reviews';

export default function Reviews({ description, mod, background }) {
  const [state, setState] = useState({
    reviews: stub,
    video: {},
    showMoreActive: false,
    loading: false,
    current: 0,
    allowHint: true,
  });

  const toggleShowMore = useCallback(
    () => setState(prev => ({ ...prev, showMoreActive: !state.showMoreActive })),
    [state.showMoreActive],
  );

  const handleBeforeChange = useCallback((prev, next) => {
    setState(prev => ({ ...prev, current: next, allowHint: false }));
  }, []);

  const handleAfterChange = useCallback(() => {
    setState(prev => ({ ...prev, allowHint: true }));
  }, []);

  const settings = useMemo(
    () => ({
      customPaging: CustomDot.bind(null, state.reviews?.length, state.current),
      appendDots: CustomPagination,
      dotsClass: 'slick-pagination',
      autoplay: false,
      initialSlide: 0,
      autoplaySpeed: 10000,
      dots: true,
      infinite: true,
      lazyLoad: 'progressive',
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 544,
          settings: {
            adaptiveHeight: true,
            arrows: true,
          },
        },
      ],
    }),
    [state.reviews?.length, state.current],
  );

  return (
    <Container
      id="reviews"
      background="none"
      className={cn(styles.container, styles[mod], 'reviewSlider')}
    >
      {background && (
        <Image
          src={background}
          alt="background"
          className={cn(styles.picture)}
          classImage={cn(styles.pictureImage)}
          lazy={false}
        />
      )}
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <Title color="white" tag="p" size="h2">
            {'Что говорят наши клиенты'}
          </Title>
        </header>
        {description && <Subtitle size="lg">{description}</Subtitle>}
        <div className={cn(styles.cardsWrapper, 'slider-wrapper')}>
          {state.loading && <Loader theme="fullHeight" />}
          {state.reviews?.length > 0 && (
            <>
              <Slider
                {...settings}
                beforeChange={handleBeforeChange}
                afterChange={handleAfterChange}
              >
                {state.reviews?.map((item, i) => (
                  <ReviewInfo
                    key={item.id}
                    allowHint={state.allowHint}
                    showMoreActive={state.showMoreActive}
                    toggleShowMore={toggleShowMore}
                    theme="slider"
                    {...item}
                  />
                ))}
              </Slider>
            </>
          )}
        </div>
      </div>
    </Container>
  );
}
