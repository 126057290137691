import React from 'react';
import { Subtitle, Modal, CloseButton } from 'landing/ui';
import * as styles from './ErrorModal.module.scss';

export default function ErrorModal({ isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      closeButton={false}
      animation="opacity"
      closeModalCallback={closeModal}
      theme="error"
    >
      {onClose => (
        <>
          <CloseButton onClose={onClose} />
          <div>
            <Subtitle size="xlg" className={styles.text}>
              К сожалению, заявка не была отправлена.
            </Subtitle>
            <Subtitle size="xlg" className={styles.contact}>
              {'Попробуйте снова'}
            </Subtitle>
          </div>
        </>
      )}
    </Modal>
  );
}
