export const anchorLinks = [
  {
    anchor: 'about',
    alias: '#about',
    title: 'О продукте',
  },
  {
    anchor: 'what-we-do',
    alias: '#what-we-do',
    title: 'Как мы можем помочь',
  },
  {
    anchor: 'team',
    alias: '#team',
    title: 'Команда',
  },
  {
    anchor: 'reviews',
    alias: '#reviews',
    title: 'Отзывы',
  },
  {
    anchor: 'contacts',
    alias: '#contacts',
    title: 'Связаться',
  },
];
