// extracted by mini-css-extract-plugin
export var accordion = "GetConsultation-module--accordion--cbb09";
export var box = "GetConsultation-module--box--b1160";
export var btnContainer = "GetConsultation-module--btnContainer--dd386";
export var captchaLeft = "GetConsultation-module--captchaLeft--a7581";
export var captchaRight = "GetConsultation-module--captchaRight--f8a34";
export var column = "GetConsultation-module--column--a0b30";
export var form = "GetConsultation-module--form--3867a";
export var mobileContacts = "GetConsultation-module--mobileContacts--c42f9";
export var slider = "GetConsultation-module--slider--72bf4";
export var wrapper = "GetConsultation-module--wrapper--2f59f";
export var сontact = "GetConsultation-module--сontact--df81c";
export var сontactsField = "GetConsultation-module--сontactsField--39d3a";
export var сontactsValue = "GetConsultation-module--сontactsValue--0e637";