// extracted by mini-css-extract-plugin
export var box = "OurTeam-module--box--465fa";
export var button = "OurTeam-module--button--140d5";
export var description = "OurTeam-module--description--c8272";
export var descriptionShort = "OurTeam-module--descriptionShort--9fd9e";
export var image = "OurTeam-module--image--9d858";
export var item = "OurTeam-module--item--7685b";
export var itemTitle = "OurTeam-module--itemTitle--7d946";
export var layout = "OurTeam-module--layout--28706";
export var link = "OurTeam-module--link--86edc";
export var linkWrapper = "OurTeam-module--linkWrapper--14e86";
export var list = "OurTeam-module--list--8842c";
export var title = "OurTeam-module--title--eb625";
export var wrapper = "OurTeam-module--wrapper--c6479";