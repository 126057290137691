// extracted by mini-css-extract-plugin
export var box = "Reviews-module--box--acea9";
export var button = "Reviews-module--button--54467";
export var buttonRow = "Reviews-module--buttonRow--40ba5";
export var cardsWrapper = "Reviews-module--cardsWrapper--519ef";
export var container = "Reviews-module--container--f885d";
export var counter = "Reviews-module--counter--af617";
export var header = "Reviews-module--header--324a6";
export var img = "Reviews-module--img--309d7";
export var img_mobile = "Reviews-module--img_mobile--bdac2";
export var mobileNav = "Reviews-module--mobileNav--be6be";
export var picture = "Reviews-module--picture--01ff7";
export var pictureImage = "Reviews-module--pictureImage--1218f";
export var pictureImage_mobile = "Reviews-module--pictureImage_mobile--7d533";
export var picture_mobile = "Reviews-module--picture_mobile--df326";
export var video = "Reviews-module--video--70379";
export var wrapper = "Reviews-module--wrapper--1f7a2";