import React from 'react';
import cn from 'classnames';

import { Container, Markup, Image, Button } from 'landing/ui';

import * as styles from './AboutProject.module.scss';
import { Link } from 'gatsby';

export default function AboutProject({ title, subtitle, description, img }) {
  return (
    <Container background="variable" id="about">
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <div className={styles.item}>
            <p className={styles.itemTitle}>{title}</p>
            {subtitle && (
              <Markup className={cn(styles.itemTitle, styles.titleSecond)} tag="h3" size="h4">
                {subtitle}
              </Markup>
            )}
            {description && (
              <Markup className={cn(styles.description)} tag="p">
                {description}
              </Markup>
            )}

            <GoToButton className={styles.inner} />
          </div>
        </div>
        <div className={cn(styles.itemImage)}>
          <Image src={img.src} alt="background" lazy />
        </div>
        <GoToButton className={styles.outer} />
      </div>
    </Container>
  );
}

const GoToButton = ({ className }) => (
  <Link to="/about-product" href={`/about-product`} className={className}>
    <Button className={styles.btn} type="submit" size={'md'} fullWidthMobile>
      <span className={styles.arrowRight}>Подробнее</span>
    </Button>
  </Link>
);
