import React from 'react';

import { Container, Title, Grid, GridItem, List, Markup } from 'landing/ui';

import * as styles from './OurTeam.module.scss';
import { LazyImage } from '../../landing/ui';

export default function OurTeam({ title, items }) {
  return (
    <>
      <Container id="team">
        <div className={styles.wrapper}>
          <Title tag="h2" className={styles.title}>
            {title}
          </Title>
          <div className={styles.layout}>
            <Grid
              xsm={{ cols: 1 }}
              sm={{ cols: 1 }}
              md={{ cols: 3 }}
              lg={{ cols: 3 }}
              xl={{ cols: 3 }}
            >
              {items.map(({ title, descriptionShort, points, image }) => (
                <GridItem key={title}>
                  <div className={styles.item}>
                    <LazyImage src={image} className={styles.image} />
                    <Title tag="h3" size="h4" className={styles.itemTitle}>
                      {title}
                    </Title>
                    {descriptionShort && (
                      <Markup className={styles.descriptionShort} tag="p">
                        {descriptionShort}
                      </Markup>
                    )}
                    {points && <List items={points} theme="gray" size="md" />}
                  </div>
                </GridItem>
              ))}
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}
