import React, { lazy, Suspense, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Title, Subtitle, Loader, CaptchaInfo, Markup } from 'landing/ui';
import { TopScroller } from 'landing/components';
import { useIsSsr } from 'utils/hooks';
import * as styles from './GetConsultation.module.scss';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
const CorporateForm = lazy(() => import('components/Forms/CorporateForm/CorporateForm'));

export default function GetConsultation({
  formId = 'get-consultation',
  title = 'Contact us for a free consultation',
  stepTitle = '',
  contacts,
  description,
  isMainPage,
}) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const isSsr = useIsSsr();

  return (
    <Container id="contacts" padding="Without" className={styles.wrapper}>
      <div className={styles.column}>
        {contacts.map(({ field, value }) => (
          <div key={value} className={styles.сontact}>
            <div className={styles.сontactsField}>{field}</div>
            <Markup tag="div" className={styles.сontactsValue}>
              {value}
            </Markup>
          </div>
        ))}
        <CaptchaInfo classname={styles.captchaRight} captchaBGColor="gray" />
      </div>
      <div ref={ref} className={styles.column}>
        <Title tag="h2">{title}</Title>
        {description && <Subtitle size="lg">{description}</Subtitle>}
        {inView && !isSsr && (
          <Suspense fallback={<Loader theme="block" />}>
            <CorporateForm
              id={formId}
              className={styles.form}
              onError={() => setError(true)}
              onSuccess={() => setSuccess(true)}
              submitTitle={'Отправить'}
              captchaInfo={false}
              formName="get-consultation"
            />
          </Suspense>
        )}
        <div className={styles.btnContainer}>
          <TopScroller isMainPage />
        </div>
      </div>
      <div className={styles.mobileContacts}>
        {contacts.map(({ field, value }) => (
          <div key={value} className={styles.сontact}>
            <div className={styles.сontactsField}>{field}</div>
            <Markup tag="div" className={styles.сontactsValue}>
              {value}
            </Markup>
          </div>
        ))}
        <CaptchaInfo classname={styles.captchaLeft} captchaBGColor="gray" />
      </div>
      <SuccessModal
        isOpen={success}
        closeModal={() => {
          setSuccess(false);
        }}
      />
      <ErrorModal isOpen={error} closeModal={() => setError(false)} />
    </Container>
  );
}
