// extracted by mini-css-extract-plugin
export var box = "ReviewInfo-module--box--76d0c";
export var card = "ReviewInfo-module--card--cdaea";
export var caseLink = "ReviewInfo-module--caseLink--1685e";
export var caseLinkRow = "ReviewInfo-module--caseLinkRow--46389";
export var caseLogo = "ReviewInfo-module--caseLogo--51d89";
export var description = "ReviewInfo-module--description--c7405";
export var header = "ReviewInfo-module--header--c629d";
export var headerInfo = "ReviewInfo-module--headerInfo--1250b";
export var hide = "ReviewInfo-module--hide--6310f";
export var hideDesktop = "ReviewInfo-module--hideDesktop--d0811";
export var hideMobile = "ReviewInfo-module--hideMobile--472f0";
export var icon = "ReviewInfo-module--icon--20328";
export var layout = "ReviewInfo-module--layout--9264b";
export var less = "ReviewInfo-module--less--d4beb";
export var location = "ReviewInfo-module--location--4faaa";
export var locationImg = "ReviewInfo-module--locationImg--ec7b7";
export var moreButton = "ReviewInfo-module--moreButton--a785b";
export var moreLessButton = "ReviewInfo-module--moreLessButton--326d9";
export var noTransform = "ReviewInfo-module--noTransform--d3a2f";
export var review = "ReviewInfo-module--review--68fcc";
export var section = "ReviewInfo-module--section--987ea";
export var sectionInfo = "ReviewInfo-module--sectionInfo--fa7bd";
export var shortDescription = "ReviewInfo-module--shortDescription--307c5";
export var showFull = "ReviewInfo-module--showFull--009be";
export var showMoreButtonRow = "ReviewInfo-module--showMoreButtonRow--77d4e";
export var slide = "ReviewInfo-module--slide--87101";
export var slider = "ReviewInfo-module--slider--fe29d";
export var technologiesItem = "ReviewInfo-module--technologiesItem--ffbeb";
export var text = "ReviewInfo-module--text--6d98e";
export var textWrapper = "ReviewInfo-module--textWrapper--e122e";
export var title = "ReviewInfo-module--title--96477";
export var videoDescription = "ReviewInfo-module--videoDescription--05c51";
export var wrapper = "ReviewInfo-module--wrapper--1c293";