import React from 'react';
import { LazyImage } from 'landing/ui';
import * as styles from './PersonalData.module.scss';

export default function PersonalData({ photo, name, linkedin, position, company, companyLink }) {
  return (
    <figure className={styles.wrapper} key={name}>
      <LazyImage className={styles.photo} src={photo} alt={name ?? 'Person'} />
      <figcaption className={styles.caption}>
        <p className={styles.name}>{name}</p>
        <p className={styles.position}>{position}</p>
      </figcaption>
    </figure>
  );
}
